import React from "react";
import { Link, graphql, StaticQuery } from 'gatsby';
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import ProductCard from '../components/Shop/ProductCard';
import NavbarStyleThree from "../components/_App/NavbarStyleThree";
import ProductImage from "../assets/images/services-image/img.jpg";
// import Shape1 from "../assets/images/shape1.png";
// import Shape2 from "../assets/images/shape2.svg";
// import Shape3 from "../assets/images/shape3.svg";
// import Shape4 from "../assets/images/shape4.svg";
import { getImageURL } from "../format-date";
const GET_PRODUCT_LIST = graphql`
query MyQuery {
    allStrapiProducts {
        nodes {
            product_name
            product_price
            product_slug
            product_image {
                localFile {
                  id
                  extension
                  name
                }
            }
            preview_url
            gumroad_purchase_link
          }
    }
    strapiProductPageSeo {
        seo_title
        seo_image{
          localFile{
            name
            extension
          }
        }
        seo_description
        product_page_title
        product_page_description
      }
  }  
`;
const Shop = () => (
    <StaticQuery
        query={GET_PRODUCT_LIST} render={data => {
            data.allStrapiProducts.nodes.forEach(product => {
                product.srcset = "";
                product.sizes = "";
                product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 300)} 300w,`;
                product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 450)} 450w,`;
                product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 500)} 600w,`;
                product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 600)} 600w,`;
                product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 800)} 800w,`;
                product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 1200)} 1200w,`;
                product.sizes += `(max-width: 1200px) 40vw, 1200px`;
            });
            return (
                <Layout>
                    <SEO title={data.strapiProductPageSeo.seo_title} type="website" url="/products/" description={data.strapiProductPageSeo.seo_description} image={getImageURL(data.strapiProductPageSeo.seo_image.localFile.name, data.strapiProductPageSeo.seo_image.localFile.extension)} />
                    <NavbarStyleThree />
                    {/* <PageBanner pageTitle={data.strapiProductPageSeo.product_page_title} /> */}
                    {/* <ProductCard /> */}
                    <div className="shop-area ptb-80 pt-180 bg-gradient">
                        {/* <div className="container">
                            <div className="woocommerce-topbar">
                                <div className="row align-items-center">
                                    <div className="col-lg-9 col-md-7 col-sm-7">
                                        <div className="woocommerce-result-count">
                                            <p>Showing 1-8 of 14 results</p>
                                        </div>
                                    </div>
    
                                    <div className="col-lg-3 col-md-5 col-sm-5">
                                        <div className="woocommerce-topbar-ordering">
                                            <select className="form-select">
                                                <option value="1">Sort by Popularity</option>
                                                <option value="2">Sort by Average Rating</option>
                                                <option value="3">Sort by Latest</option>
                                                <option value="4">Sort by price: Low to High</option>
                                                <option value="5">Sort by price: High to Low</option>
                                                <option value="6">Sort by New</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="Product-section">
                        <div className="ml-services-area ptb-80">
                            <div className="container-fluid padd-2">
                                <div className="section-title">
                                    {/* <span className="sub-title">Our Solutions</span> */}
                                    <h2>{data.strapiProductPageSeo.product_page_title}</h2>
                                    <div className="bar"></div>
                                    <p>{data.strapiProductPageSeo.product_page_description}</p>
                                </div>

                                    <div className="row justify-content-center">
                                        {data.allStrapiProducts.nodes.map((product, i) => (
                                            <div className="col-lg-6 col-sm-6" key={product.product_slug}>
                                                <div className="single-ml-services-box">
                                                    <div className="image">
                                                        <Link to={`/products/${product.product_slug}/`}>
                                                            <img
                                                                src={getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension)}
                                                                alt={product.product_name} srcSet={product.srcset} sizes={product.sizes} style={{ "width": "100vw", height: "100%", objectFit: "cover", objectPosition: "center center", opacity: 1, transition: "opacity 500ms ease 0s" }} loading="lazy" />
                                                        </Link>
                                                    </div>
                                                    <div className="row p-lrb">
                                                        <div className="col-lg-12 col-sm-12">
                                                            <h3 className="">
                                                                <Link to={`/products/${product.product_slug}/`} className="product-name">
                                                                    {product.product_name}
                                                                </Link>
                                                            </h3>
                                                        </div>
                                                        <div className="col-lg-12 col-sm-12 text-center">
                                                            <Link className="btn btn-blue">
                                                                ${product.product_price}
                                                            </Link>
                                                            &nbsp;&nbsp;&nbsp;
                                                            &nbsp;&nbsp;&nbsp;
                                                            <Link to={`/products/${product.product_slug}/`} className="btn btn-pink">
                                                                View Details
                                                            </Link>
                                                            &nbsp;&nbsp;&nbsp;
                                                            &nbsp;&nbsp;&nbsp;
                                                            <Link to={product.preview_url} target="_blank" className="btn btn-primary-outline mt-10">
                                                                Live Preview
                                                            </Link>
                                                        </div>
                                                    </div>

                                                    {/* <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p> */}
                                                </div>
                                            </div>
                                        ))}
                                        {/* <div className="col-lg-4 col-sm-6">
                            <div className="single-ml-services-box">
                                <div className="image">
                                    <img src={ServiceImage2} alt="image" />
                                </div>
                                <h3>
                                    <Link to="/service-details">
                                        Software Engineers
                                    </Link>
                                </h3>
                                <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-ml-services-box">
                                <div className="image">
                                    <img src={ServiceImage3} alt="image" />
                                </div>
                                <h3>
                                    <Link to="/service-details">
                                        Health & Manufacturing
                                    </Link>
                                </h3>
                                <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-ml-services-box">
                                <div className="image">
                                    <img src={ServiceImage4} alt="image" />
                                </div>
                                <h3>
                                    <Link to="/service-details">
                                        Security & Surveillance
                                    </Link>
                                </h3>
                                <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-ml-services-box">
                                <div className="image">
                                    <img src={ServiceImage5} alt="image" />
                                </div>
                                <h3>
                                    <Link to="/service-details">
                                        Automatic Optimization
                                    </Link>
                                </h3>
                                <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-ml-services-box">
                                <div className="image">
                                    <img src={ServiceImage6} alt="image" />
                                </div>
                                <h3>
                                    <Link to="/service-details">
                                        Data Analysts
                                    </Link>
                                </h3>
                                <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            </div>
                        </div> */}
                                    </div>
                                </div>

                                {/* Shape Images */}
                                {/* <div className="shape1">
                                    <img src={Shape1} alt="shape" />
                                </div>
                                <div className="shape2 rotateme">
                                    <img src={Shape2} alt="shape" />
                                </div>
                                <div className="shape3">
                                    <img src={Shape3} alt="shape" />
                                </div>
                                <div className="shape4">
                                    <img src={Shape4} alt="shape" />
                                </div>
                                <div className="shape7">
                                    <img src={Shape4} alt="shape" />
                                </div>
                                <div className="shape8 rotateme">
                                    <img src={Shape2} alt="shape" />
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="pagination-area">
                            <nav aria-label="Page navigation">
                                <ul className="pagination justify-content-center">
                                    <li className="page-item">
                                        <Link to="#" className="page-link">
                                            Prev
                                        </Link>
                                    </li>
    
                                    <li className="page-item active">
                                            <Link to="#" className="page-link">1</Link>
                                        </li>
    
                                        <li className="page-item">
                                            <Link to="#" className="page-link">1</Link>
                                        </li>
    
                                        <li className="page-item">
                                            <Link to="#" className="page-link">1</Link>
                                        </li> 
    
                                    <li className="page-item ml-2">
                                        <Link to="#" className="page-link">
                                            Next
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div> */}
                        {/* <div className="row justify-content-md-center">
                          <div className="col-lg-12 col-md-12 col-sm-12">
                          <OurServices />
                          </div>
    
                      
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            
                        </div>
                    </div> */}
                    </div>
                    <Footer />
                </Layout>
            )
        }}
    />
)


export default Shop;