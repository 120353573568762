import React from 'react';
import { Link, StaticQuery, graphql } from "gatsby";

// Shape Images
// import Shape1 from "../../assets/images/shape1.png";
// import Shape2 from "../../assets/images/shape2.svg";
// import Shape3 from "../../assets/images/shape3.svg";
// import Shape4 from "../../assets/images/shape4.svg";
import { getImageURL } from '../../format-date';
import SEO from '../_App/seo';
const GET_PRODUCT_LIST_FOR_HOME_PAGE = graphql`
query GetHomePageProducts {
    allStrapiHomePageSproducts(limit:4,skip:0) {
        nodes {
            product{
            product_name
            product_price
            slug
            product_image {
                localFile {
                  id
                  extension
                  name
                }
            }
            preview_url
          }
        }
    }
    strapiHomePageSeo{
        seo_title
        seo_description
        seo_image{
          localFile{
            name
            extension
          }
        }
      }
  }  
`;

const OurServices = ({ title, description }) => (
    <StaticQuery query={GET_PRODUCT_LIST_FOR_HOME_PAGE} render={data => {
        data.allStrapiHomePageSproducts.nodes.forEach(product => {
            product.srcset = "";
            product.sizes = "";
            product.srcset += `${getImageURL(product.product.product_image.localFile.name, product.product.product_image.localFile.extension, 300)} 300w,`;
            product.srcset += `${getImageURL(product.product.product_image.localFile.name, product.product.product_image.localFile.extension, 450)} 450w,`;
            product.srcset += `${getImageURL(product.product.product_image.localFile.name, product.product.product_image.localFile.extension, 500)} 600w,`;
            product.srcset += `${getImageURL(product.product.product_image.localFile.name, product.product.product_image.localFile.extension, 600)} 600w,`;
            product.srcset += `${getImageURL(product.product.product_image.localFile.name, product.product.product_image.localFile.extension, 800)} 800w,`;
            product.srcset += `${getImageURL(product.product.product_image.localFile.name, product.product.product_image.localFile.extension, 1200)} 1200w,`;
            product.sizes += `(max-width: 1200px) 40vw, 1200px`;
        });
        return (
            <div>
                <SEO title={data.strapiHomePageSeo.seo_title} type="website" url="/" description={data.strapiHomePageSeo.seo_description} image={getImageURL(data.strapiHomePageSeo.seo_image.localFile.name, data.strapiHomePageSeo.seo_image.localFile.extension)} />
                <div className="ml-services-area ptb-80">
                    <div className="container-fluid padd-2">
                        <div className="section-title">
                            {/* <span className="sub-title">Our Solutions</span> */}
                            <h2>{title}</h2>
                            <div className="bar"></div>
                            <p>{description}</p>
                        </div>

                        <div className="row justify-content-center">
                            {data.allStrapiHomePageSproducts.nodes.map((product) => (
                                <div className="col-lg-6 col-md-6 col-12" key={product.product.slug}>
                                    <div className="single-ml-services-box">
                                        <div className="image">
                                            <Link to={`/products/${product.product.slug}/`} className="product-name">
                                                <img
                                                    src={getImageURL(product.product.product_image.localFile.name, product.product.product_image.localFile.extension)}
                                                    srcSet={product.srcset} sizes={product.sizes} alt={product.product.product_name}
                                                    style={{ "width": "100%", height: "100%", objectFit: "cover", objectPosition: "center center", opacity: 1, transition: "opacity 500ms ease 0s" }}
                                                    loading="lazy" />
                                            </Link>
                                        </div>
                                        <div className="row p-lrb">
                                            <div className="col-lg-12 col-sm-12">
                                                <h3 className="">
                                                    <Link to={`/products/${product.product.slug}/`} className="product-name">
                                                        {product.product.product_name}
                                                    </Link>
                                                </h3>
                                            </div>
                                            <div className="col-lg-12 col-sm-12 text-center">
                                                <Link className="btn btn-blue">
                                                    ${product.product.product_price}
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={`/products/${product.product.slug}/`} className="btn btn-pink">
                                                    View details
                                                </Link>
                                                &nbsp;&nbsp;&nbsp;
                                                &nbsp;&nbsp;&nbsp;
                                                <Link to={product.product.preview_url} target="_blank" className="btn btn-primary-outline mt-10">
                                                    Live Preview
                                                </Link>
                                            </div>
                                        </div>

                                        {/* <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Shape Images */}
                    {/* <div className="shape1">
                        <img src={Shape1} alt="shape" />
                    </div>
                    <div className="shape2 rotateme">
                        <img src={Shape2} alt="shape" />
                    </div>
                    <div className="shape3">
                        <img src={Shape3} alt="shape" />
                    </div>
                    <div className="shape4">
                        <img src={Shape4} alt="shape" />
                    </div>
                    <div className="shape7">
                        <img src={Shape4} alt="shape" />
                    </div>
                    <div className="shape8 rotateme">
                        <img src={Shape2} alt="shape" />
                    </div> */}
                </div>
            </div>
        );
    }}></StaticQuery>
);

export default OurServices;
